@import 'tailwind';
@import 'fonts';
@import 'import';

input,
button {
    outline: none;

    &:focus {
        outline: none;
    }
}
